import { Box, Typography } from "@mui/material"
import { makeStyles } from "@mui/styles"
import { getFormattedFreeCardDate } from "utils/getFormattedFreeCardDate"

const useStyles = makeStyles((theme) => ({
  sticky: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    "& p": {
      fontSize: "0.75rem",
      color: theme.palette.primary.main,
      fontWeight: 600,
    },
  },
  box: {
    marginTop: theme.spacing(2),
    marginLeft: "auto",
    marginRight: "auto",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      width: "90%",
    },
  },
  background: {
    background: "#F7F7F7",
    padding: theme.spacing(2, 3),
    "& p": {
      fontSize: "0.75rem",
    },
  },
  title: {
    fontWeight: 600,
    color: theme.palette.primary.main,
  },
}))

const NotifyFrikortSticky = ({ type = "sticky", expirationDate }) => {
  const classes = useStyles()

  const renderText = () => {
    if (type === "sticky") {
      return (
        <Typography>
          Frikort t.o.m. {getFormattedFreeCardDate(expirationDate)} ditt besök
          kostar 0 kr
        </Typography>
      )
    }
    return (
      <>
        <Typography className={classes.title}>
          Aktivt frikort t.o.m. {getFormattedFreeCardDate(expirationDate)}
        </Typography>
        <Typography>
          Du har uppnått högkostnadsskyddet och besöket kostar 0 kr.
        </Typography>
      </>
    )
  }

  return (
    <Box
      className={[
        classes.background,
        type === "sticky" ? classes.sticky : classes.box,
      ]}
    >
      {renderText()}
    </Box>
  )
}

export default NotifyFrikortSticky
