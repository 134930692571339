/* eslint-disable no-nested-ternary */
import React, { useState, useCallback, useEffect, useRef } from "react"
import { connect } from "react-redux"
import { Link, useNavigate, useLocation } from "react-router-dom"
import {
  Box,
  AppBar,
  Toolbar,
  Drawer,
  IconButton,
  Button,
  Typography,
  Divider,
  Link as MuiLink,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Hidden,
  Backdrop,
  Container,
  Menu,
  MenuItem,
  Badge,
} from "@mui/material"
import { makeStyles } from "@mui/styles"
import chatSessionHandler from "utils/chatSessionHandler"
import { navigationValues } from "utils/navigationValues"
import config from "config"
import { Logo } from "assets"
import {
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
  Home as HomeIcon,
  HomeOutlined as HomeOutlinedIcon,
  Videocam as VideocamIcon,
  VideocamOutlined as VideocamOutlinedIcon,
  Mail as MailIcon,
  MailOutline as MailOutlineIcon,
} from "@mui/icons-material/"
import DefaultAvatar from "components/DefaultAvatar"
import { showAlert } from "redux/common"
import classNames from "classnames"
import { logout } from "redux/user"
import BurgerMenuIcon from "./BurgerMenuIcon"
import Banners from "./Banners"
import VideoTestModal from "./VideoTestModal"

const COMPANY_NAME = config.company_name
const DRAWER_WIDTH = "80%"
const CURRENT_YEAR = new Date().getFullYear()

const useStyles = makeStyles((theme) => ({
  root: (height) => ({
    position: "relative",
    height,
    display: "flex",
    flexDirection: "column",
    "& .MuiToolbar-root": {
      minHeight: "64px",
    },
  }),
  wrapper: {
    display: "flex",
    flexDirection: "row",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "#fff",
    height: 64,
    position: "static",
    "& .MuiButton-startIcon": {
      marginRight: theme.spacing(0.5),
    },
    [theme.breakpoints.down("sm")]: {
      position: "fixed",
    },
  },
  titleContainer: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      justifyContent: "flex-start",
    },
  },
  drawer: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
  drawerPaper: {
    width: DRAWER_WIDTH,
    top: "64px",
  },
  displayName: {
    color: "#000",
  },
  container: {
    display: "flex",
    flexDirection: "row",
    flexGrow: 1,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      marginTop: theme.spacing(8),
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer - 1,
  },
  videoButton: {
    textTransform: "none",
    textDecoration: "underline",
    fontWeight: theme.typography.fontWeightBold,
    fontSize: "0.89rem",
    color: theme.palette.primary.main,
    "&:hover, &:active, &:focus": {
      background: "none",
      textDecoration: "underline",
    },
    [theme.breakpoints.up("md")]: {
      "& svg": {
        marginRight: theme.spacing(1),
      },
    },
    [theme.breakpoints.down("md")]: {
      "& .MuiListItemIcon-root": {
        minWidth: theme.spacing(4),
        color: "inherit",
      },
      "& .MuiListItemText-root > *": {
        fontWeight: "inherit",
        color: "inherit",
      },
    },
  },
  menuList: {
    "& .MuiListItemIcon-root": {
      minWidth: "30px",
    },
  },
  menuPaper: {
    borderRadius: 0,
  },
  menuItem: {
    color: theme.palette.black,
    lineHeight: theme.spacing(4.5),
  },
  menuDivider: {
    margin: theme.spacing(0, 1),
    backgroundColor: theme.palette.light_gray,
  },
  headerButton: {
    marginRight: theme.spacing(2),
  },
  active: {
    fontWeight: 600,
    color: theme.palette.primary.main,
    "& > *": {
      color: theme.palette.primary.main,
      "& span": {
        fontWeight: 600,
      },
    },
  },
  badge: {
    "& .MuiBadge-badge": {
      height: "13px",
      minWidth: "13px",
      width: "13px",
      color: theme.palette.white,
      backgroundColor: theme.palette.error.main,
      fontWeight: 600,
      fontSize: "0.51rem",
    },
  },
  mailIcon: {
    fontSize: "1.25rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.35rem",
    },
  },
}))

const toggleDrawer = (isOpen, setOpen) => (event) => {
  if (
    event.type === "keydown" &&
    (event.key === "Tab" || event.key === "Shift")
  ) {
    return
  }

  setOpen(isOpen)
}

const ListItemLink = (props) => {
  const { icon, primary, to, className, sameWindow, toggleMenu } = props
  const navigate = useNavigate()

  return (
    <ListItem
      button
      onClick={(event) =>
        sameWindow
          ? (toggleMenu(event), navigate(to))
          : window.open(to, "_blank")
      }
      className={className}
    >
      {icon && <ListItemIcon>{icon}</ListItemIcon>}
      <ListItemText primary={primary} />
    </ListItem>
  )
}

const RenderDrawer = ({
  isOpen,
  setOpen,
  shouldDisplayBookMeetingLink,
  toggleMenu,
  navigationValue,
  shouldBadgeDisplay,
  ...rest
}) => {
  const classes = useStyles()
  return (
    <Drawer variant="persistent" open={isOpen} {...rest}>
      <List className={classes.menuList}>
        <Hidden mdDown mdUp>
          {shouldDisplayBookMeetingLink && (
            <ListItemLink
              primary="Boka videosamtal"
              icon={<VideocamIcon />}
              className={classes.videoButton}
              sameWindow
              to="/video"
              toggleMenu={toggleMenu}
            />
          )}
        </Hidden>
        <ListItemLink
          sameWindow
          to="/"
          primary="Hem"
          icon={
            navigationValue === navigationValues.HOME ? (
              <HomeIcon />
            ) : (
              <HomeOutlinedIcon />
            )
          }
          toggleMenu={toggleMenu}
          className={
            navigationValue === navigationValues.HOME ? classes.active : ""
          }
        />
        <ListItemLink
          sameWindow
          to="/video"
          primary="Video"
          icon={
            navigationValue === navigationValues.VIDEO ? (
              <VideocamIcon />
            ) : (
              <VideocamOutlinedIcon />
            )
          }
          toggleMenu={toggleMenu}
          className={
            navigationValue === navigationValues.VIDEO ? classes.active : ""
          }
        />
        <ListItemLink
          sameWindow
          to="/messages"
          primary="Meddelanden"
          icon={
            navigationValue === navigationValues.MESSAGES ? (
              <Badge
                badgeContent={shouldBadgeDisplay ? 1 : 0}
                className={classes.badge}
              >
                <MailIcon className={classes.mailIcon} />
              </Badge>
            ) : (
              <Badge
                badgeContent={shouldBadgeDisplay ? 1 : 0}
                className={classes.badge}
              >
                <MailOutlineIcon className={classes.mailIcon} />
              </Badge>
            )
          }
          toggleMenu={toggleMenu}
          className={
            navigationValue === navigationValues.MESSAGES ? classes.active : ""
          }
        />
        <Divider />
        <ListItem>
          <Typography variant="caption">
            &#169; {COMPANY_NAME} {CURRENT_YEAR}
          </Typography>
        </ListItem>
      </List>
    </Drawer>
  )
}

const isUserWithStartedChatAndNoBookedMeeting = (
  isChatSessionGranted,
  freeAgeOrFreeCardUser,
  chatStarted,
  videoMeetingStart
) =>
  isChatSessionGranted &&
  (!freeAgeOrFreeCardUser || chatStarted) &&
  !videoMeetingStart

const Header = ({ children, session, user, chatStarted, dispatch }) => {
  const {
    avatarUrl,
    firstName,
    lastName,
    freeCard,
    customerStatus,
    latestPayment,
    meeting: videoMeeting,
    unreadMessagesNumber,
    unfilledFormsNumber,
    onboardingFinished: hasUserFinishedOnboarding,
  } = user
  const shouldBadgeDisplay = unreadMessagesNumber || unfilledFormsNumber
  const [isOpen, setOpen] = useState(false)
  const [isMenuOpen, setMenuOpen] = useState(false)
  const [height, setHeight] = useState(window.innerHeight - 0.1)
  const [shouldDisplayBookMeetingLink, setShouldDisplayBookMeetingLink] =
    useState(false)
  const [navigationValue, setNavigationValue] = useState(null)
  const classes = useStyles(height)
  const location = useLocation()
  const iconButtonRef = useRef()
  const handleHeight = useCallback((e) => {
    setHeight(e.target.innerHeight - 0.1)
  }, [])
  const [isPageWithBanners, setIsPageWithBanners] = useState(false)
  const [videoTestOpen, setVideoTestOpen] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    window.addEventListener("resize", handleHeight)
    return () => {
      window.removeEventListener("resize", handleHeight)
    }
  }, [handleHeight])

  useEffect(() => {
    const checkIfBookMeetingLinkShouldBeDisplayed = async () => {
      const { activeSession: isChatSessionGranted, freeAgeOrFreeCardUser } =
        await chatSessionHandler(false)
      const displayBookMeetingLink = isUserWithStartedChatAndNoBookedMeeting(
        isChatSessionGranted,
        freeAgeOrFreeCardUser,
        chatStarted,
        videoMeeting?.start
      )
      setShouldDisplayBookMeetingLink(displayBookMeetingLink)
    }
    checkIfBookMeetingLinkShouldBeDisplayed()
  }, [
    freeCard,
    customerStatus,
    latestPayment,
    videoMeeting?.start,
    session,
    chatStarted,
  ])

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    if (searchParams.get("testVideo") === "true") {
      setVideoTestOpen(true)
    }
  }, [location.search])

  useEffect(() => {
    const path = location.pathname
    if (!hasUserFinishedOnboarding) {
      setNavigationValue(navigationValues.VIDEO)
      setIsPageWithBanners(true)
    } else {
      switch (path) {
        case "/":
          setNavigationValue(navigationValues.HOME)
          setIsPageWithBanners(true)
          break
        case "/video":
          setNavigationValue(navigationValues.VIDEO)
          setIsPageWithBanners(true)
          break
        case "/messages":
          setNavigationValue(navigationValues.MESSAGES)
          setIsPageWithBanners(true)
          break
        default:
          setNavigationValue(null)
          setIsPageWithBanners(false)
          break
      }
    }
    if (
      !sessionStorage.getItem("shownUnreadMessagesNotification") &&
      hasUserFinishedOnboarding &&
      shouldBadgeDisplay &&
      path !== "/payment-successful"
    ) {
      dispatch(
        showAlert({
          type: "info",
          message: (
            <Typography>
              Du har fått nya{" "}
              <MuiLink
                href={`${window.location.origin}/messages`}
                underline="always"
                color="white"
              >
                meddelanden eller formulär
              </MuiLink>{" "}
              att fylla i.
            </Typography>
          ),
        })
      )
      sessionStorage.setItem("shownUnreadMessagesNotification", true)
    }
  }, [location])

  const handleVideoTestToggle = (open = true) => {
    if (open) {
      navigate(`${location.pathname}?testVideo=true`)
      setVideoTestOpen(true)
    } else {
      navigate(location.pathname)
      setVideoTestOpen(false)
    }
  }

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <AppBar className={classes.appBar} data-testid="header">
          <Toolbar>
            {hasUserFinishedOnboarding && (
              <Hidden mdUp>
                <IconButton onClick={toggleDrawer(!isOpen, setOpen)}>
                  <BurgerMenuIcon open={isOpen} />
                </IconButton>
              </Hidden>
            )}

            <Box className={classes.titleContainer}>
              <Logo />
            </Box>

            {hasUserFinishedOnboarding && (
              <>
                <Hidden mdDown>
                  <Hidden mdUp>
                    {shouldDisplayBookMeetingLink && (
                      <Button
                        disableRipple
                        component={Link}
                        to="/video"
                        className={classes.videoButton}
                      >
                        <VideocamIcon />
                        Boka videosamtal
                      </Button>
                    )}
                  </Hidden>
                  <Button
                    disableRipple
                    component={Link}
                    color="default"
                    to="/"
                    startIcon={
                      navigationValue === navigationValues.HOME ? (
                        <HomeIcon />
                      ) : (
                        <HomeOutlinedIcon />
                      )
                    }
                    className={`${classes.headerButton} ${
                      navigationValue === navigationValues.HOME
                        ? classes.active
                        : ""
                    }`}
                  >
                    Hem
                  </Button>
                  <Button
                    disableRipple
                    component={Link}
                    to="/video"
                    color="default"
                    startIcon={
                      navigationValue === navigationValues.VIDEO ? (
                        <VideocamIcon />
                      ) : (
                        <VideocamOutlinedIcon />
                      )
                    }
                    className={`${classes.headerButton} ${
                      navigationValue === navigationValues.VIDEO
                        ? classes.active
                        : ""
                    }`}
                  >
                    Video
                  </Button>
                  <Button
                    disableRipple
                    component={Link}
                    to="/messages"
                    color="default"
                    startIcon={
                      navigationValue === navigationValues.MESSAGES ? (
                        <Badge
                          badgeContent={shouldBadgeDisplay ? 1 : 0}
                          className={classes.badge}
                        >
                          <MailIcon className={classes.mailIcon} />
                        </Badge>
                      ) : (
                        <Badge
                          badgeContent={shouldBadgeDisplay ? 1 : 0}
                          className={classes.badge}
                        >
                          <MailOutlineIcon className={classes.mailIcon} />
                        </Badge>
                      )
                    }
                    className={`${classes.headerButton} ${
                      navigationValue === navigationValues.MESSAGES
                        ? classes.active
                        : ""
                    }`}
                  >
                    Meddelanden
                  </Button>
                </Hidden>

                <IconButton
                  onClick={() => setMenuOpen(true)}
                  ref={iconButtonRef}
                >
                  <DefaultAvatar
                    imgProps={{
                      className: classNames({ "ph-no-capture": avatarUrl }),
                    }}
                    src={avatarUrl}
                    name={`${firstName} ${lastName}`}
                  />
                  {isMenuOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </>
            )}
          </Toolbar>
        </AppBar>

        <Menu
          classes={{ paper: classes.menuPaper }}
          elevation={3}
          anchorEl={iconButtonRef.current}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={isMenuOpen}
          onClose={() => setMenuOpen(false)}
        >
          <MenuItem className={classes.menuItem} component={Link} to="/profile">
            Min Profil
          </MenuItem>
          <Divider className={classes.menuDivider} />
          <MenuItem
            className={classes.menuItem}
            onClick={() => window.open(config.faq, "_blank")}
          >
            Vanliga frågor
          </MenuItem>
          <MenuItem
            className={classes.menuItem}
            onClick={handleVideoTestToggle}
          >
            Videotest
          </MenuItem>
          <MenuItem
            className={classes.menuItem}
            onClick={() => window.open(config.support, "_blank")}
          >
            Support
          </MenuItem>
          <Divider className={classes.menuDivider} />
          <MenuItem
            className={classes.menuItem}
            onClick={() => {
              dispatch(logout())
            }}
          >
            Logga ut
          </MenuItem>
        </Menu>

        <RenderDrawer
          className={classes.Drawer}
          classes={{
            paper: classes.drawerPaper,
          }}
          navigationValue={navigationValue}
          isOpen={isOpen}
          setOpen={setOpen}
          shouldDisplayBookMeetingLink={shouldDisplayBookMeetingLink}
          toggleMenu={toggleDrawer(false, setOpen)}
          shouldBadgeDisplay={shouldBadgeDisplay}
        />
        <Backdrop
          className={classes.backdrop}
          open={isOpen}
          onClick={toggleDrawer(false, setOpen)}
        />
      </div>
      {isPageWithBanners && <Banners />}
      <Container maxWidth="lg" disableGutters className={classes.container}>
        {children}
      </Container>
      <VideoTestModal open={videoTestOpen} close={handleVideoTestToggle} />
    </div>
  )
}
const mapStateToProps = (state) => {
  const { user, chat, common } = state
  const { session } = chat
  const { chatStarted } = common

  return {
    session,
    user,
    chatStarted,
  }
}

export default connect(mapStateToProps)(Header)
